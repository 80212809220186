import React,{useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import ButtonField from "../../common/component/ButtonField";
import InputField from "../../common/component/InputField";
import "../Style/ServerConfiguration.css"
import BreadCrumbIcon from '../../assets/images/breadcrum.png';
import BreadCrumb from '../../common/component/BreadCrumb';
import * as functions from "../../common/functions/validateFunctions";
import URL from "../../common/api/constantURL";
import { setSnackData,setLoading} from "../../redux/action/userAction";
import * as API from '../../common/api/index.js';
import {setServerConfig} from '../../redux/action/serverConfigAction'
import { validatePermission } from "../../common/functions/permissionFunctions"

function ServerConfiguration(props){
   
	const BreadCrumbArr = [
		{link:"/home/dashboard",display:"Dashboard",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon} alt='arrow-icon' />,type:'img'},
		{link:"/home/system/server-configuration",display:"System",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon} alt='arrow-icon' />,type:'img'},
		{link:"",display:"Server Configuration",type:"react"},
	];
	const dispatch = useDispatch()
	const server = useSelector(state => state.serverConfigReducer.serverConfig)
	const userPermissions = useSelector(state => state.userReducer.userPermissions)
   
	const [saraIpAddress, setSaraIpAddress] = useState("");
	const [saraIpAddressError, setSaraIpAddressError] = useState(false);

	const [saraUserName, setSaraUserName] = useState('');
	const [saraUserNameError, setSaraUserNameError] = useState(false);
   
	const [saraPassword, setSaraPassword] = useState("");
	const [saraPasswordError, setSaraPasswordError] = useState(false);

	const [umsSecretKey, setUmsSecretKey] = useState("");
	const [umsSecretKeyError, setUmsSecretKeyError] = useState(false);

	const [umsPassword, setUmsPassword] = useState("");
	const [umsPasswordError, setUmsPasswordError] = useState(false);

	const [umsSiteId, setUmsSiteId] = useState("");
	const [umsSiteIdError, setUmsSiteIdError] = useState(false);


	const [mqttServerUserName, setMqttServerUserName] = useState("");
	const [mqttServerUserNameError, setMqttServerUserNameError] = useState(false);

	const [mqttServerPassword, setMqttServerPassword] = useState("");
	const [mqttServerPasswordError, setMqttServerPasswordError] = useState(false);


	const [updateBtn,setUpdateBtn] = useState(false);
	useEffect(()=>{
		fetchServerConfigDetails(); 
	},[])

	const fetchServerConfigDetails = async () => {
		dispatch(setLoading(true))	
		let url = URL.serverConfiguration + "?id=" + localStorage.getItem("facilityId");
		let response = await API.getAPI(url)
		dispatch(setLoading(false))	
		dispatch(setServerConfig(response.result))
		setSaraIpAddress(response.result.saraIp || "");
		setSaraUserName(response.result.saraUsername || "");
		setSaraPassword(response.result.saraPassword || "")
		setUmsSecretKey(response.result.umsUsername || "")
		setUmsPassword(response.result.umsPassword || "")
		setUmsSiteId(response.result.siteId || "")
		setMqttServerUserName(response.result.mqttUsername || "")
		setMqttServerPassword(response.result.mqttPassword || "")
	}

	const handleUMScredentials = () => (umsPassword.length !== 0  || umsSecretKey.length !== 0 || umsSiteId.length !== 0);
		
	const formHandling = async () => {
		let validateSaraIpAddress = functions.validateInput(saraIpAddress, setSaraIpAddressError, "all"),
			validateSaraUserName = functions.validateInput(saraUserName, setSaraUserNameError, "all"),
			validateSaraPassword = functions.validateInput(saraPassword, setSaraPasswordError, "all"),
			validateUmsSecretKey = functions.validateInput(umsSecretKey, setUmsSecretKeyError, "all", handleUMScredentials()),
			validateUmsPassword = functions.validateInput(umsPassword, setUmsPasswordError, "all", handleUMScredentials()),
			validateUmsSiteId = functions.validateInput(umsSiteId, setUmsSiteIdError, "numeric", handleUMScredentials()),
			validateMqttUserName = functions.validateInput(mqttServerUserName, setMqttServerUserNameError, "all"),
			validateMqttPassword = functions.validateInput(mqttServerPassword, setMqttServerPasswordError, "all");
		let requestData = []
		if (validateSaraIpAddress &&
			validateSaraUserName &&
			validateSaraPassword &&
			validateUmsSecretKey &&
			validateUmsPassword &&
			validateUmsSiteId &&
			validateMqttUserName &&
			validateMqttPassword 
		) {
			requestData = {
				"id": localStorage.getItem("facilityId"),
				"saraIp": saraIpAddress,
				"saraUsername": saraUserName,
				"saraPassword": saraPassword,
				"umsUsername": umsSecretKey ? umsSecretKey : null,
				"umsPassword": umsPassword ? umsPassword : null,
				"siteId": umsSiteId ? umsSiteId : null,
				"mqttUsername": mqttServerUserName,
				"mqttPassword": mqttServerPassword,
			}
			if (requestData.saraIp === server.saraIp &&
				requestData.saraUsername === server.saraUsername &&
				requestData.saraPassword === server.saraPassword &&
				requestData.umsUsername === server.umsUsername &&
				requestData.umsPassword === server.umsPassword &&
				requestData.siteId === server.siteId &&
				requestData.mqttUsername === server.mqttUsername &&
				requestData.mqttPassword === server.mqttPassword 
			) {
				
				setUpdateBtn(true)
			}
			else {
				dispatch(setLoading(true))
				let response = null;
				setUpdateBtn(false)
				response = await API.putAPI(URL.updateServerConfig, requestData);
				
				dispatch(setLoading(false))
				if (response.fetchStatus === "success") {
					if (response.result.status === "success") {
						let snackData = {
							showSnack: true,
							snackMessage: response.result.message,
							snackVariant: "success"
						}
						dispatch(setSnackData(snackData))
						fetchServerConfigDetails();
					} else {
						let snackData = {
							showSnack: true,
							snackMessage: response.result.message,
							snackVariant: "error"
						}
						dispatch(setSnackData(snackData))
					}
				}
			}
			setUmsSiteIdError(false);
			setUmsPasswordError(false);
			setUmsSecretKeyError(false)
		}
	}
	return (
		<div className="server-configuration" data-testid="server-page-container">
			<BreadCrumb crumbs={BreadCrumbArr} dataTestid={"bread-crums-list"}></BreadCrumb>
			<div className="list-page-title-div">
				<div data-testid='server-config-title' className="list-page-title">Server Configuration Options</div>
			</div>
			
			<div> 
				<div className="server-configuration-container">
					<div className="server-configuration-inner-container" data-testid="server-configuration-inner-ctr">
						{/* <div>
							<h5>CATIE Server</h5>
							<InputField label="IP address" placeholder="10.2.50.29"/>
						</div> */}
						<div className="sara-credentials">
							<h5 data-testid="sara-credentials">SARA Credentials</h5>
							<div className="server-config-input-container">  
								<InputField 
									id="ipaddress"
									dataTestid="ipaddress"
									label="IP address *" 
									placeholder="SARA server address"
									autocomplete="off"
									value={saraIpAddress}
									className={saraIpAddressError ? " invalid " : ""}
									onChange={(event) => {setUpdateBtn(false);functions.validateInputChange(event, setSaraIpAddress, setSaraIpAddressError)}}
								/>
								<div className='input-error-message' id="sara-ip-addressError" data-testid="sara-ip-addressError" >{saraIpAddressError}</div>
							</div>
							<InputField 
								id="sara-user-name"
								dataTestid="sara-user-name"
								label="User Name *" 
								placeholder="user name" 
								autocomplete="off"
								value={saraUserName}
								className={saraUserNameError ? " invalid " : ""}
								onChange={(event) => {setUpdateBtn(false);functions.validateInputChange(event, setSaraUserName,setSaraUserNameError)}}
							/>
							<div className='input-error-message' id="sara-userName-Error"  data-testid="sara-userName-Error">{saraUserNameError}</div>
							<InputField 	
								id="sara-password"
								dataTestid="sara-password"
								label="Password *" 
								placeholder="password" 
								autocomplete="off"
								type="password" 
								value={saraPassword}
								className={saraPasswordError ? " invalid " : ""}
								onChange={(event) => {setUpdateBtn(false);functions.validateInputChange(event, setSaraPassword,setSaraPasswordError)}}
							/>
							<div className='input-error-message' id="sara-pasword-Error" data-testid="sara-pasword-Error" >{saraPasswordError}</div>
						</div>
						<div className="ums-credentials">
							<h5 data-testid="ums-credentials">UMS Credentials</h5>
							<InputField 
								id="ums-secret-key"
								dataTestid="ums-secret-key"
								label="Secret Key *" 
								placeholder="key" 
								autocomplete="off"
								value={umsSecretKey}
								className={umsSecretKeyError ? " invalid " : ""}
								onChange={(event) => {setUpdateBtn(false);functions.validateInputChange(event, setUmsSecretKey,setUmsSecretKeyError)}}
								/>
								<div className='input-error-message' id="ums-secretKey-Error" data-testid="ums-secretKey-Error">{umsSecretKeyError}</div>
							<InputField 
								id='ums-password'
								dataTestid='ums-password'
								label="Password *" 
								placeholder="password" 
								autocomplete="off"
								type="password" 
								value={umsPassword}
								className={umsPasswordError ? " invalid " : ""}
								onChange={(event) => {setUpdateBtn(false);functions.validateInputChange(event, setUmsPassword,setUmsPasswordError)}}
								/>
								<div className='input-error-message' id="ums-passsword-Error" data-testid="ums-passsword-Error" >{umsPasswordError}</div>
							<InputField 
								id="ums-site-id"
								dataTestid="ums-site-id"
								label="Site ID *" 
								placeholder="site id" 
								autocomplete="off"
								value={umsSiteId}
								className={umsSiteIdError ? " invalid " : ""}
								onChange={(event) => {setUpdateBtn(false);functions.validateInputChange(event, setUmsSiteId,setUmsSiteIdError)}}
								/>
								<div className='input-error-message' id="ums-siteId-Error" data-testid="ums-siteId-Error">{umsSiteIdError}</div>
						</div>
						<div className="mqtt-credentials">
							<h5 data-testid="mqtt-credentials-title">MQTT Credentials</h5>
							<InputField
								id="mqtt-user-name"
								dataTestid="mqtt-user-name"
								label="User Name *"
								placeholder="user name"
								autocomplete="off"
								value={mqttServerUserName}
								className={mqttServerUserNameError ? " invalid " : ""}
								onChange={(event) => { setUpdateBtn(false); functions.validateInputChange(event, setMqttServerUserName, setMqttServerUserNameError) }}
							/>
							<div className='input-error-message' id="mqtt-userName-Error" data-testid="mqtt-userName-Error">{mqttServerUserNameError}</div>
							<InputField
								id="mqtt-password"
								dataTestid="mqtt-password"
								label="Password *"
								placeholder="password"
								autocomplete="off"
								type="password"
								value={mqttServerPassword}
								className={mqttServerPasswordError ? " invalid " : ""}
								onChange={(event) => { setUpdateBtn(false); functions.validateInputChange(event, setMqttServerPassword, setMqttServerPasswordError) }}
							/>
							<div className='input-error-message' id="mqtt-pasword-Error" data-testid="mqtt-pasword-Error" >{mqttServerPasswordError}</div>
						</div>
						{/* <div>
							<h5>SIP Server</h5>
							<InputField label="SIP Server" placeholder="10.2.50.29"/>
						</div> */}
						{/* <div >
							<h5>Server Type</h5>
							<fieldset className="server-type">
								<div className="radio-button-group">
									<input type="radio" name="Role" value="Production"/><label>Production</label>
								</div>
								<div className="radio-button-group">
									<input type="radio" name="Role" value="Demo"/><label>Demo</label>
								</div>
							</fieldset>             
						</div> */}
					</div>
					<div className='update-error-message' data-testid="server-error-msg">{updateBtn?"No details modified.":''}</div>
					{validatePermission(userPermissions, "UPDATE_SERVER_CONFIG") ? 
						<div className="server-config-button-container server-button-container" >
							<ButtonField
								label="Save"
								id="server-page-save-container"
								dataTestid="server-page-save-container"
								onClick={() => formHandling()}
								type = "submit" 
							/>
						</div>
					:null}
				</div> 
			</div>
		</div>
	)
}

export default ServerConfiguration;
