import BreadCrumbIcon from '../../assets/images/breadcrum.png';
import BreadCrumb from '../../common/component/BreadCrumb';
import Table from '../../common/component/Table';
import { useDispatch, useSelector } from 'react-redux';
import * as API from '../../common/api/index.js';
import URL from "../../common/api/constantURL";
import {useEffect, useState} from 'react';
import { setSnackData, setLoading} from "../../redux/action/userAction"
function Support() {
	const dispatch = useDispatch();
	const searchData = useSelector(state=>state.deviceReducer.deviceSearchData)
	const [search,setSearch]=useState(searchData);
	const [deviceList,setDevicesList]=useState([]);

	useEffect(() => {
		dispatch(setLoading(true))
		setSearch("")
		fetchDeviceLogsList();
	}, []);

	useEffect(()=>{},[deviceList])

	const fetchDeviceLogsList = async () => {
		let url = URL.fetchDeviceLogsList + "?facilityId=" + localStorage.getItem("facilityId");
		let response = await API.getAPI(url)
		dispatch(setLoading(false))
		if(response.fetchStatus === "success"){
			let deviceList = response.result.result
			if(response.result.status === "success"){
				setDevicesList(deviceList)
			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else {
			let snackData = {
				showSnack:true,
				snackMessage:"Server down.Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}

	}
	const BreadCrumbArr = [
		{ link: "/home/dashboard", display: "Dashboard", type: "react" },
		{ link: '', display: <img src={BreadCrumbIcon} alt="arrow-icon" />, type: 'img' },
		{ link: "/home/support", display: "Support", type: "react" },
		{ link: '', display: <img src={BreadCrumbIcon} alt="arrow-icon" />, type: 'img' },
		{ link: "/home/support/deviceLogs", display: "Device Logs", type: "react" },
	];

  const filter = (values) =>{
	let updatedValues = []
	if(search && search.length > 0){
		values && values.map((value)=>{
			if((value.deviceName||"").toLocaleLowerCase().includes(search.toLocaleLowerCase())){
				updatedValues.push(value)
			}
		})
	}else{
		updatedValues = values
	}
	return updatedValues
	}

	const fetchDeviceLogs = async (row) => {
		let url = URL.downloadDeviceLogs + "?facilityId=" + localStorage.getItem("facilityId") + "&logFile=" + row.logFile;
		dispatch(setLoading(true))
		let response = await API.downloadFile(url)
		dispatch(setLoading(false))
		if(response.fetchStatus === "success"){
			let snackData = {
				showSnack:true,
				snackMessage: "Log Files downloaded successfully.",
				snackVariant:"success"
				}
			dispatch(setSnackData(snackData))
			
		}else {
			let snackData = {
				showSnack:true,
				snackMessage:"Server down.Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	let tableHeaders = [
		{ id: 'deviceName', label: 'Username/Device Name', width: 120, wordBreak:"break-word", fontWeight:"bold", responsive: true, sortable: true, dataTestid: "deviceName" },
		{ id: 'lastReceivedTime', label: 'Last Received', width: 80, responsive: true, dataTestid: "lastReceived"},
		{ id: 'pendingStatus', label: "Pending", width: 80, type: 'check_icon',justifyContent : "center", responsive: true, dataTestid: "pendingStatus" },
		{ id: 'downloadAction', label: 'MANAGE', type: "downloadAction", path: '#', width: 70, responsive: true , justifyContent : "center" , clickFunc: fetchDeviceLogs}
	]
	const handleSearch=(e)=>{
		setSearch(e.target.value)
	}

	const formatTableData = () => {
		let deviceListData = deviceList
		let tempData = []
		deviceListData && deviceListData.forEach((device) => {
			let tempObj = {}
			tempObj = device
			if(device.userName){
				tempObj["deviceName"] = device.userName
			}
			tempData.push(tempObj)
		})
		return tempData
	}

	let deviceListData = formatTableData();

	return <>
		<div className="device-page" id="wrapper">
			<div>
				<BreadCrumb crumbs={BreadCrumbArr} dataTestid="devices-breadcrum" ></BreadCrumb>

				<div className='addDevice-content'>
					<div className="list-page-title-div">
						<p data-testid="device-log-title-id" className='list-page-title'>Devices Logs</p>
					</div>


					<div className='rowDivSearch'>
						<div className="col col-33 search device-page-search-container">
							<input type="text" value={search} data-testid="device-search-box" id="users-search-box" className="usersSearch" placeholder="Search" onChange={handleSearch}></input>
						</div>
							
						<div style={{ display: "flex"}} className="reclear user-reset-btn-ctr searchReset">
						<div data-testid="device-reset-btn" id="users-reset-btn"  className='pointer' onClick={()=>{setSearch("");}} >Reset</div>
						</div>
					</div>
					
				</div>
				<div className='device-table-container'>
					<div>
						{<Table headers={tableHeaders}
							isTitleVisible={false}
              				data={filter(deviceListData)}
							defaultOrderBy={"deviceName"}
							defaultSortOrder={"asc"}
							perPageData={10}
						>
						</Table>}
					</div>
				</div>
			
			</div>

		</div>
	</>
}
export default Support
