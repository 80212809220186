import React,{useEffect,useState,useRef} from 'react'
import "../style/BatchImportPopUp.css";
import * as functions from "../../common/functions/validateFunctions";
function FileUploadPopUp(props) {

    const { sendDataToParent, initialData } = props;
    const fileUpload = useRef(null);
    const [errorMessage,setErrorMessage]=useState("")
    const [documentData,setDocumentData] = useState([])
    const [data,setData] = useState([]);
    const [imgLabel,setImgLabel] = useState("");
    const [docLabel,setDocLabel] = useState("");
    const [isLabel, setIsLabel] = useState(true);
    const [index, setIndex] = useState();
    const [remove , setRemove] = useState(false)
    const [update, setUpdate] =  useState(false)
    const [file, setFile] = useState()
    
    let userinfo={} ;
    let userDocinfo = {}

    useEffect(async() => {
        if (initialData) {
            // Set initial value if initialData exists and is a document type
            if (props.type === "document") {
                setIndex(props.index)
                let newUserDocinfo = {
                    name: initialData.name,
                    docpreview: initialData.docpreview,
                    IconComponent: initialData.IconComponent
                };
                if (initialData.id) {
                    newUserDocinfo.id = initialData.id
                }
                setDocumentData([newUserDocinfo]);
                setDocLabel(initialData.label || "");
            }
            // Set initial value if initialData exists and is an image type
            if (props.type === "image") {
                setIndex(props.index)
                userinfo = {
                    name: initialData.name,
                    filepreview: initialData.filepreview,
                };
                if (initialData.id) {
                    userinfo.id = initialData.id
                }
                setData([userinfo])
                setImgLabel(initialData.label || "");
            }
        }
        
    }, [initialData, props.type]);

    const handleImageChange = (event) => {
        let validFileExtensions = ["image/jpg", "image/jpeg", "image/png"];
        setFile(event.target.files[0])
       if (event.target.files[0]) {
           if (validFileExtensions.includes(event.target.files[0].type)) {
               setRemove(true)
               const file = changeFileName(event.target.files[0]) 
               userinfo = {
                   file: file,
                   filepreview: URL.createObjectURL(event.target.files[0]),
               };
               setUpdate(true)
               setData([userinfo])
            } else {
                fileUpload.current.value = ''
                setFile()
                setErrorMessage("Upload file with jpg, jpeg, png file format")
           }
       }
    }

    const handleDocumentChange = (event) => {
        const validFileExtensions = ["application/pdf"];
        setFile(event.target.files[0])
        if (event.target.files[0]) {
            if (validFileExtensions.includes(event.target.files[0].type)) {
                setRemove(true)
                const fileType = event.target.files[0].type;
                const IconComponent = fileType === "application/pdf" ? "PdfIcon" : "WordDocIcon"; 
                const file = changeFileName(event.target.files[0]) 
                userDocinfo = {
                    document: file,
                    docpreview: URL.createObjectURL(event.target.files[0]),
                    IconComponent: IconComponent,
                };
                setUpdate(true)
                setDocumentData([userDocinfo]);
            } else {
                fileUpload.current.value = '';
                setFile()
                setErrorMessage("Upload file with PDF file format")
            }
        }
    };
   
  
    const UploadButtonHandlerForImage = (e) => {
        let validateimageLabel = functions.validateInput(imgLabel, setErrorMessage, "all");
        setIsLabel(true)
        if ((!initialData && !file ) || (update && !file)) {
            return setErrorMessage("Upload file")
        }

        if (props.type === "image" && !validateimageLabel) {
            return setIsLabel(false)
        }
        if(imgLabel.trim().length >100) {
            return setErrorMessage("File label should not exceed 100 characters.")
        }
        if((data.length > 0) && data[0].file) {
            const fileSize = data[0].file.size / 1024 / 1024;
                if (fileSize > 5) {
                    return setErrorMessage("Please upload a file smaller than 5 MB.")
                }
        }

        if (!initialData) {
            if ((data.length > 0) && (validateimageLabel)) {
                if (validateimageLabel) {
                    data.forEach((i) => {
                        i.label = imgLabel
                        i.displayOrder = index
                    })
                }
                sendDataToParent({ data, imgLabel, index,update });
                props.onClose()
            }
        }
        if(initialData){
            if ((validateimageLabel)) {
                if (validateimageLabel) {
                    data.forEach((i) => {
                        i.label = imgLabel
                        i.displayOrder = index
                    })
                }
                sendDataToParent({ data, imgLabel, index, remove, update });
                props.onClose()
            }
        }
    }

    const UploadButtonHandlerForDocument = (e) => {
        setIsLabel(true)
        let validateDocLabel = functions.validateInput(docLabel, setErrorMessage, "all");
        if ((!initialData && !file) || (update && !file)) {
            return setErrorMessage("Upload file")
        }
        if (props.type === "document" && !validateDocLabel) {
            return setIsLabel(false)
        }
        if(docLabel.trim().length >100) {
            return setErrorMessage("File label should not exceed 100 characters.")
        }

        if((documentData.length > 0) && documentData[0].document) {
            const fileSize = documentData[0].document.size / 1024 / 1024;
                if (fileSize > 5) {
                    return setErrorMessage("Please upload a file smaller than 5 MB.")
                }
        }

        if (!initialData) {
            if ((documentData.length > 0) && (validateDocLabel)) {
                if (validateDocLabel) {
                    documentData.forEach((i) => {
                        i.label = docLabel
                        i.displayOrder = index
                    })
                }
                sendDataToParent({ documentData, docLabel, index,remove,update });
                props.onClose()
            }
        }
        if(initialData){
            if ((validateDocLabel)) {

                documentData.forEach((i) => {
                    i.label = docLabel
                    i.displayOrder = index
                })
                sendDataToParent({ documentData, docLabel, index,remove,update });
                props.onClose()
            }
        }
    }

    const changeFileName = (file)=>{
            const fileName = `${Date.now()}_${file.name}`
            const fileObj = new File([file],fileName,{
             type : file.type,
             webkitRelativePath : file.webkitRelativePath
            })
            return fileObj;
    }
    return (
        <div className='batch-import-container' data-testid="file-upload-container">
            <div className="modal event-details">
                <div className="modal-content-container batch-import-section event-file-import">
                    <div className='batch-import-content-container'>
                        <div className='batch-import-title'  data-testid="upload-title">{initialData===null?( props.popUpTitle  ? props.popUpTitle :"Add Image"): (initialData.IconComponent ? "Edit Document"  : "Edit Image")}</div>

                        <div className='choose-ctr' data-testId = "image-type">{props.popUpSubTitle ? props.popUpSubTitle :"Choose only JPG, JPEG or PNG files."}</div>
                        <div className='batch-import-file-input'>
                            <input type="file" className='batch-import-input' data-testid="batch-import-input-btn" ref={fileUpload} onChange={(e) => {
                                setErrorMessage("");
                                props.type === "image" ? handleImageChange(e) : handleDocumentChange(e);
                            }}>
                            </input>
                        </div>
                        <div className='popup-input-ctr'>
                            <label data-testId="file-label" className={isLabel ? " event-title" : "event-title highLight"}>Add Label</label>
                            <div className='popUpInput'>
                            <input
                            value={(props.type === "image" ? imgLabel : docLabel)}
                            className={isLabel ? "event-input-field fileLabel" : "event-input-field fileLabel invalid" } 
                            placeholder='Label Name'
                            type='text'
                            onChange={(event) => {
                                functions.validateInputChange(event, props.type === "image" ? setImgLabel :setDocLabel, setErrorMessage,setIsLabel(true));  
                            }}
                            data-testId = "file-label-input"
                            >
                            </input>
                        <div className="instruction forlabel" data-testId = "label-instruction">This label will appear in CATIE Mobile and Admin.</div>
                            </div>
                        </div>
                        <div className='input-error-message batch-import-error-ctr' data-testid="batch-import-error-msg">{errorMessage}</div>
                    </div>
                    <div className='button_parent_div'>
                        <button onClick={()=>props.onClose()}className="cancel-button" data-testid="batch-import-cancel-btn" id="batch-import-cancel-btn">Cancel</button>
                        <div class="vertical_line" ></div>
                        <button data-testid="batch-import-update-btn" className='save-button' onClick={(e) => {props.type === "image" ? UploadButtonHandlerForImage(e):UploadButtonHandlerForDocument(e)}} >Add</button>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FileUploadPopUp;
